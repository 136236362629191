<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="50"
    viewBox="0 0 40 50"
  >
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <path
              class="img-path"
              :class="pathClass"
              d="M20 0C9 0 0 8.483 0 18.924c0 4.132 1.444 8.048 4 11.31l15.111 19.359c.333.435 1.111.544 1.556.217l.222-.217L36 30.234c2.556-3.262 4-7.178 4-11.31C40 8.483 31 0 20 0z"
              transform="translate(-24 -298) translate(-1) translate(25 298)"
            ></path>
            <g fill="#FFF">
              <path
                d="M4.518 11.514c-.245 0-.414-.248-.33-.481L8.13.233C8.181.093 8.312 0 8.46 0h5.087c.253 0 .423.265.32.501l-3.18 7.339h6.463c.31 0 .467.379.251.605L6.502 19.889c-.267.28-.72-.017-.578-.38l3.123-7.995h-4.53z"
                transform="translate(-1830 -449) translate(1830 449) translate(10 11)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    pathClass: Object,
  },
};
</script>

<style lang="scss" scoped>
.img-path {
  fill: var(--dark-blue);
  &.available {
    fill: var(--green);
  }
  &.unknown {
    fill: var(--dark-grey);
  }
}
</style>
